'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitGroupsManager

 # @description

###
angular
.module 'mundoAdmin.units'
.factory 'UnitGroupUsersManager', [
  'MundoDefaultManager'
  'TenantManager'
  'UnitsManager'
  '$mdDialog'
  '$log'
  'UserRoleManager'
  'UserContextManager'
  'RestUtils'
  '$q'
  (
    MundoDefaultManager
    TenantManager
    UnitsManager
    $mdDialog
    $log
    UserRoleManager
    UserContextManager
    RestUtils
    $q
  ) ->
    UnitGroupUsersManagerBase = new MundoDefaultManager()
    UnitGroupUsersManagerBase.setParentUrl('units/groups')
    UnitGroupUsersManagerBase.setUrl('users')
    UnitGroupUsersManagerBase.setNewObject(['userContext', 'roles'])
    UnitGroupUsersManagerBase.setUpdateObject(['userContext', 'roles'])

    UnitGroupUsersManagerBase.new = (id, data) ->
      if (data.roles)
        data.roles = @extractIds(data.roles)

      @transport.one(@getParentUrl(), @getParentId()).all(getUrl()).post(_.pick(data, @getNewObject()))

    UnitGroupUsersManagerBase.update = (id, data) ->
      if (data.roles)
        data.roles = @extractIds(data.roles)

      @transport.one(@conf.parentUrl, @conf.parentId).one(@getUrl(), id).customPUT(_.pick(data, @getUpdateObject()))

    UnitGroupUsersManagerBase.getNewForm = ->
      userContexts = []
      UserContextManager.getFullList()
        .then((list)->
          for item in list
            item.label = item.user.username if !item.isGroup
            userContexts.push item
        )
      [
        key: 'userContext'
        name: 'userContext'
        type: 'select'
        templateOptions:
          label: 'User context'
          placeholder: 'User context'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: userContexts
      ,
        key: 'roles'
        name: 'roles'
        type: 'modelchips'
        templateOptions:
          label: 'Roles'
          multiple: true,
          placeholder: 'roles'
          autocompleteLabel : 'add  or remove a role'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: UserRoleManager.getFullList().$object
      ]

    UnitGroupUsersManagerBase.getEditForm = (data) ->
      [
        key: 'userContext'
        name: 'userContext'
        type: 'select'
        defaultValue: data.userContext.id
        templateOptions:
          label: 'User context'
          placeholder: 'User context'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: UserContextManager.getFullList().$object
      ,
        key: 'roles'
        name: 'roles'
        type: 'modelchips'
        defaultValue: data.roles
        templateOptions:
          label: 'Roles'
          multiple: true,
          placeholder: 'roles'
          autocompleteLabel : 'add or remove a role'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: UserRoleManager.getFullList().$object
      ]

    assignUsers =
      [
        icon: 'people'
        text: 'app.admin.actions.units.groups.assignUsers'
        action: (id) ->
          $mdDialog.show
            templateUrl: 'mundo-admin/units/views/unit-group-users.modal.tpl.html'
            controller: 'UnitGroupUserCtrl'
            controllerAs: 'ctrl'
            locals:
              id: id
      ]

    UnitGroupUsersManagerBase.addExtraAction(assignUsers)

    UnitGroupUsersManagerBase
]
